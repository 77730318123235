import React from 'react';

const getOrCreateRef = (id, videoReferences) => {

  if (!videoReferences.hasOwnProperty(id)) {
    videoReferences[id] = React.createRef();
  }
  return videoReferences[id];
}

export default getOrCreateRef
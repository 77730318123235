import React, {useState, useRef} from 'react';
import axios from 'axios';
import styled from 'styled-components';
import propTypes from 'prop-types';
import {get} from 'lodash';
import {Grid, Box} from '@material-ui/core';
import {TextField, RadioGroup, FormControlLabel, FormControl} from '@material-ui/core';
import {useForm, Controller} from "react-hook-form";
import isEmail from 'validator/lib/isEmail';
// import isPostalCode from 'validator/lib/isPostalCode';
import ReCAPTCHA from "react-google-recaptcha";
import Router from 'next/router';
import { v4 as uuidv4 } from 'uuid';

import Checkbox from '../../forms/Checkbox'
import Radio from '../../forms/Radio';
import Dropdown from '../../ui/Dropdown';
import Button from '../../ui/Cta';
import {theme} from '../../../themes/default-theme';
import Text from '../../typography';
import FileUpload from '../../forms/FileUpload';


const Wrapper = styled.div`
  max-width: 780px;
  margin: 0 auto;
`

const Form = styled.form`
  text-align: left;
`

const FormItem = styled(FormControl)`
  display: block;
  margin: 30px 0;
`

const Error = styled.p`
  color: ${theme.palette.error.form}
` 

const DynamicForm = ({
  formData,
  formId,
  form_id,
  uri
}) => {

  // const countryCode = (process.env.DOMAIN.indexOf('com-au') || process.env.DOMAIN.indexOf('com.au')) ? "AU" : "NZ"
  const [sending, setSending] = useState(false);
  const [error, setError] = useState(null);
  const [captchaError, setCaptchaError] = useState(null);

  let defaultValues = {}

  formData.forEach(item => {
    if (item.type === 'checkbox') {
      item.options && item.options.forEach(checkboxItem => {
        defaultValues[checkboxItem.label] = false || undefined // checkboxItem.value || undefined // no 'active' coming from the backend
      })
    }
    if (item.type === 'radio') {
      const defaultRadioVal = item.options && item.options.find(radioItem => {
        return radioItem.value !== undefined
      })
      defaultValues[item.label] = defaultRadioVal ? defaultRadioVal.optionValue : undefined
    }
  });

  const { register, handleSubmit, watch, errors, control, formState, getValues } = useForm({
    defaultValues
  });

  const [googleCaptcha, setGoogleCaptcha] = useState(false);

  const onSubmit = data => {

    const recaptchaValue = recaptchaRef.current.getValue();

    const formVals = getValues()

    let refatorDropdowns = {}
    let refatorCheckboxes = {}
    let bodyFormData = new FormData();

    formData.forEach(item => {
      if (item.type === 'select') {
        refatorDropdowns[item.label] = data[item.label] && data[item.label].value ? data[item.label].value : undefined
      }
      if (item.type === 'checkbox') {
        let vals = []
        item.options.forEach(item => {
          if (formVals[item.label] && formVals[item.label] === true ) {
            vals.push(item.label)
          }
        })

        // console.log('vals!', vals.join())

        refatorCheckboxes[item.label] = vals.join()
      }
    });

    const dealerId = process.env.DEALER_SITE && window.staticly.dealerData.id ? {
      dealer: window.staticly.dealerData.id
    } : {}

    const dynamicFormData = {
      ...data,
      ...refatorDropdowns,
      ...refatorCheckboxes,
      'g-recaptcha-response': googleCaptcha,
      form_id: formId ? formId : form_id,
      ...dealerId
    }

    // console.log('dynamicFormData', dynamicFormData, data);
    let formattedData = {};

    Object.keys(dynamicFormData).forEach(key => {
      if (typeof dynamicFormData[key] === 'object' && !dynamicFormData[key].value && dynamicFormData[key].length > 0) {
        // For file upload
        formattedData[key] = [];
        for(var i = 0; i < dynamicFormData[key].length; i++){
          let item = dynamicFormData[key][i];
          bodyFormData.append('files[' + i + ']', item.file);
          formattedData[key].push({
            path: item.file
          });
        }
      } else {
        dynamicFormData[key] && bodyFormData.append(key.toLowerCase(), dynamicFormData[key].toString() || '');
      }
    })
    

    if (googleCaptcha && recaptchaValue.length > 0 && Object.keys(errors).length === 0) {
      setCaptchaError(null);

      const endpoint = process.env.STATICLY_FORMS_API_ENDPOINT;

      const thankYouUrl = window.location.pathname + '/thank-you';

      setSending(true)

      // axios.post(endpoint, dynamicFormData)
      axios({
        method: 'post',
        url: endpoint,
        data: bodyFormData,
        headers: {'Content-Type': 'multipart/form-data'}
      })
      .then((response) => {
        process.env.NODE_ENV === 'development' || process.env.DEALER_SITE ?
          window.location.href = thankYouUrl
        :
        Router.push(thankYouUrl)
      })
      .catch((error) => {
        console.log('Form error: ', error);
        setError('Something went wrong. Please try again later.');
        setSending(false)
      });

    } else {
      setCaptchaError('Google captcha not complete');
      setSending(false)
    }
  }

  const checkEmail = (val, e) => {
    if (e.required || val.length > 0) {
      return isEmail(val)
    }
    return true
  }

  const checkCheckbox = (val, e) => {
    const parentRequired = e.required === 'on' ? true : false;
    if (parentRequired) {
      const getVals = getValues()
      const hasVal = Object.keys(getVals).find(key => {
        return getVals[key] === true
      });
      return hasVal ? true : false
    }
    return true
  }

  const recaptchaRef = useRef();

  const uniqueId = uuidv4();

  return (
    <Wrapper>
      <Grid container className={'hasPadding'} spacing={0} direction={'row'} wrap="nowrap" justify={'space-between'} align={'center'}>
        <Grid item xs={12}>
          <Box px={2}>
            <Form id={formId} noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            
            {formData && formData.map((item, index) => {
              // const name = item.label.replace(/\s/g, '_').toLowerCase()
              const name = item.label;
              const error = get(errors, `[${name}]`, false);
              const rules = item.required ? { required: true } : {}
              const values = getValues();
              const val = values[name];
                switch(item.type) {
                  case 'input' :
                  return (
                    <FormItem key={index}>
                      <Controller
                        as={TextField} 
                        control={control}
                        rules={rules}
                        name={name}
                        placeholder={item.placeholder}
                        variant="outlined"
                        error={error ? true : false}
                        fullWidth
                        defaultValue={item.defaultValue || ''}
                      />
                    </FormItem>
                  )
                }
                switch(item.type) {
                  case 'name' :
                  return (
                    <FormItem key={index}>
                      <Controller
                        as={TextField} 
                        control={control}
                        rules={rules}
                        name={name}
                        placeholder={item.placeholder}
                        variant="outlined"
                        error={error ? true : false}
                        fullWidth
                        defaultValue={item.defaultValue || ''}
                      />
                    </FormItem>
                  )
                }
                switch(item.type) {
                  case 'email' :
                  return (
                    <FormItem key={index}>
                      <Controller
                        type="email"
                        as={TextField}
                        control={control}
                        rules={{
                          ...rules,
                          type: 'custom',
                          validate: (e) => checkEmail(e, item)
                        }}
                        name={name}
                        placeholder={item.placeholder}
                        variant="outlined"
                        error={error ? true : false}
                        fullWidth
                        defaultValue={item.defaultValue || ''}
                      />
                    </FormItem>
                  )
                }
                switch(item.type) {
                  case 'phone' :
                  return (
                    <FormItem key={index}>
                      <Controller
                        type="tel"
                        as={TextField}
                        control={control}
                        rules={{
                          ...rules,
                          type: 'custom',
                          minLength: 10,
                          maxLength: 13
                        }}
                        name={name}
                        placeholder={item.placeholder}
                        variant="outlined"
                        error={error ? true : false}
                        fullWidth
                        defaultValue={item.defaultValue || ''}
                      />
                    </FormItem>
                  )
                }
                // switch(item.type) {
                //   case 'postcode' :
                //   return (
                //     <FormItem key={index}>
                //       <Controller
                //         type="tel"
                //         as={TextField}
                //         control={control}
                //         rules={{
                //           ...rules,
                //           type: 'custom',
                //           minLength: 3,
                //           maxLength: 4,
                //           validate: (val) => isPostalCode(val, 'AU')
                //         }}
                //         name={name}
                //         placeholder={item.placeholder}
                //         variant="outlined"
                //         error={error ? true : false}
                //         fullWidth
                //         defaultValue={item.defaultValue || ''}
                //       />
                //     </FormItem>
                //   )
                // }
                switch(item.type) {
                  case 'select' :
                  return (
                    <FormItem key={index}>
                      <Controller
                        as={
                          <Dropdown 
                            variant="outlined"
                            showComplete={true}
                            error={error ? true : false}
                            label={item.label}
                            options={item.options.map((item, i) => {
                              return {
                                id: `dropdown_${index}_${i}`,
                                value: item.optionValue,
                                text: item.label,
                              }
                            })}
                            onChange={(e) => console.log('Dropdown has changed: ', e)}
                            defaultValue={item.defaultValue || undefined}
                          />
                        }
                        control={control}
                        rules={{
                          ...rules,
                          type: 'custom'
                        }}
                        name={name}
                      />
                    </FormItem>
                  )
                }
                switch(item.type) {
                  case 'textarea' :
                  return (
                    <FormItem key={index}>
                      <Controller
                        as={TextField} 
                        control={control}
                        rules={rules}
                        name={name}
                        type="textarea"
                        multiline={true}
                        placeholder={item.placeholder}
                        variant="outlined"
                        error={error ? true : false}
                        fullWidth
                        defaultValue={item.defaultValue || ''}
                        rows={item.rows || 5}
                      />
                      
                    </FormItem>
                  )
                }
                switch(item.type) {
                  case 'checkbox' :
                  return (
                    <FormItem key={index}>
                    {item.label && 
                      <Text variant="body1">{item.label}</Text>
                    }
                    {item.options.map((checkbox, index) => {
                      const checkboxName = checkbox.label;
                      const getVals = getValues()
                      const parentRequired = item.required === 'on' ? true : false;

                      const hasVal = Object.keys(getVals).find(key => {
                        return getVals[key] === true
                      })

                      const _errors = [...Object.keys(errors)]

                      let checkboxError = false

                      if (parentRequired && _errors.length > 0) {
                        if (hasVal === undefined) {
                          checkboxError = true
                        }
                      } else {
                        if (errors && errors[checkboxName]) {
                          checkboxError = true
                        } else {
                          checkboxError = false
                        }
                      }

                      const defaultVal = checkbox.value === 'on' ? true : false;
                      const checkboxRules = (checkbox.required === 'on') ? { required: true } : {}

                      // const checkboxRules = ((checkbox.required === 'on') || parentRequired && !hasVal) ? { required: true } : {}

                      return (
                        <Controller
                          key={checkbox.label}
                          as={Checkbox}
                          defaultValue={defaultVal}
                          name={checkboxName}
                          type="checkbox"
                          control={control}
                          label={checkbox.label}
                          error={checkboxError ? true : false}
                          // onChange={setFakeCheckUpdate(checkbox.label)}
                          rules={{
                            ...checkboxRules,
                            type: 'custom',
                            validate: (e) => checkCheckbox(e, item)
                          }}
                        />
                      )
                    })}
                    </FormItem>
                  )
                }
                switch(item.type) {
                  case 'radio' : {
                    const defaultVal = item.options.find(i => i.value === 'on');
                    // console.log('defaultVal', defaultVal.label, item);
                    return (
                      <FormItem key={index}>
                        {item.label && 
                          <Text variant="body1">{item.label}</Text>
                        }
                        <Controller
                          control={control}
                          rules={{
                            ...rules,
                            type: 'custom'
                          }}
                          name={name}
                          defaultValue={defaultVal && defaultVal.label || undefined}
                          as={
                            <RadioGroup
                              defaultValue={defaultVal && defaultVal.label || undefined}
                              aria-label={item.label}
                              name={item.label}
                              onChange={(event) => console.log('Radio val', event.target.value)}
                            >
                            {item.options.map((radio, index) => (
                              <FormControlLabel 
                              key={radio.label} 
                              //value={radio.optionValue} 
                              control={
                                <Radio 
                                  value={radio.label || undefined}
                                  error={error ? true : false}
                                />
                              } label={radio.label} />
                            ))}
                            </RadioGroup>
                          }
                        />
                      </FormItem>
                    )
                  }
                }
              })}
              {uri === 'requests-for-ambassadorship-friends-of-jayco' &&
                <FormItem>
                  <Controller
                    as={
                      <FileUpload 
                        uniqueId={uniqueId}
                        // defaultValue={formState['files[]']}
                        text="Drag and drop your files"
                        label="Upload your proposal and any supporting documents"
                      />
                    }
                    control={control}
                    rules={{
                      type: 'custom'
                    }}
                    name='files'
                  />
                </FormItem>
              }
              <FormItem>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={process.env.GOOGLE_RECAPTCHA_SITE_KEY}
                  onChange={setGoogleCaptcha}
                />
                {!googleCaptcha && captchaError &&
                  <Error>{captchaError}</Error>
                }
                {error &&
                  <Error>{error}</Error>
                }
              </FormItem>
              <FormItem>
                <Button inline type="submit">{sending ? 'Submitting...'  : 'Submit'}</Button>
              </FormItem>
            </Form>
          </Box>
        </Grid>
      </Grid>
    </Wrapper>
  )
}
  
DynamicForm.propTypes = {
  formData: propTypes.array.isRequired,
  formId: propTypes.number,
  form_id: propTypes.number,
  uri: propTypes.string
}
  
DynamicForm.defaultProps = {
  formData: [],
  formId: null,
  form_id: null,
  uri: ''
}
  
export default DynamicForm
import React, {useState} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {Grid, Box} from '@material-ui/core';
import {get} from 'lodash';

import topoPng from '../../../assets/topo-block-blue.png';
import {theme} from '../../../themes/default-theme';
import TabsMobileDropdown from '../../ui/TabsMobileDropdown';
import Text from '../../typography';
import Gallery from '../Gallery';

const Wrapper = styled.div`
  background-color: ${props => props.bg ? theme.palette[props.bg] : 'transparent'};
  position: relative;
`

const TabsContainer = styled.div`
  margin-bottom: 40px;

  ${theme.breakpoints.down('sm')} {
    margin-bottom: 10px;
  }
`

const Container = styled.div`
  margin: 0 auto;
`

const TextContainer = styled.div`
  margin-bottom: 20px;

  ${theme.breakpoints.up('md')} {
    margin-bottom: 60px;
  }
`

const Topo = styled.div`
  display: none;
  ${theme.breakpoints.up("md")} {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    overflow: hidden;
    background-image: url(${topoPng});
    background-position: center;
    width: 250px;
    height: 40px;
    background-size: 204px 138px;
  }

  ${theme.breakpoints.up('lg')} {
    width: 433px;
    height: 87px;
    background-size: 405px 226px;
  }
`

const TopoContainer = styled.div`
  position: relative;
  max-width: 1600px;
  margin: 0 auto;
  padding: 50px 0 30px;
  ${theme.breakpoints.up('lg')} {
    padding: 110px 0 90px;
  }
`

const TabbedGallery = (props) => {

  const {
    items,
    heading,
    bg,
    hTag
  } = props

  const initialActiveTab = get(items, '[0].title', null);
  const [activeTab, setActiveTab] = useState(initialActiveTab);

  const updateTab = (e) => {
    e.text && setActiveTab(e.text);
  }

  return (
    <Wrapper bg={bg}>
      <TopoContainer>
        <Topo />
        {heading && <Grid container className={'hasPadding'} spacing={0} direction={'row'} wrap="nowrap" justify={'space-between'} align={'center'}>
          <Grid item xs={12}>
            <Box px={2}>
              <TextContainer>
                <Text variant="h2" element={hTag ? hTag : 'h2'} align="center" uppercase colour="charcoal">{heading}</Text>
              </TextContainer>
            </Box>
          </Grid>
        </Grid> }
        <Grid container className={'hasPadding'} spacing={0} direction={'row'} wrap="nowrap" justify={'space-between'} align={'center'}>
          <Grid item xs={12}>
            <Box px={2}>
              <Container>
                <TabsContainer>
                  {items && items.length > 0 &&
                    <TabsMobileDropdown
                      active={activeTab}
                      items={items.map(item => {return {text: item.title}})}
                      variant="inline"
                      tabGap={false}
                      onChange={(e) => updateTab(e)}
                    />
                  }
                </TabsContainer>
                {(items && items.length > 0) ? items.map((item, index) => {
                  return (
                    activeTab === item.title &&
                    <Gallery 
                      key={item.title || index}
                      items={item.items}
                    />
                  )
                }) : null}
              </Container>
            </Box>
          </Grid>
        </Grid>
      </TopoContainer>
    </Wrapper>
  )
}

TabbedGallery.propTypes = {
  bg: propTypes.string,
  heading: propTypes.string,
  items: propTypes.array.isRequired
}

TabbedGallery.defaultProps = {
  bg: 'lightGrey',
  heading: null,
  items: []
}

export default TabbedGallery
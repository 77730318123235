import React, {useRef, useState} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import {theme} from '../../../themes/default-theme';
import Slider from '../Slider';
import Banner from '../Banner';
import getOrCreateRef from '../../../util/getOrCreateRef';

let videoReferences = [];

const Container = styled.div`
  ${process.env.DEALER_SITE && `
    max-width: 1600px;
    margin: 0 auto;
  `}
  ${theme.breakpoints.up('lg')} {
    ${props => props.loaded === 0 &&
      `height: 860px;`
    }
  }
  .slick-active {
    z-index: 1;
  }
  .slick-arrow {
    margin: auto;
    position: absolute;
    z-index: 1;
    display: block;
    top: 50%;
    transform: translateY(-30px);
    ${theme.breakpoints.up('lg')} {
      bottom: 70px;
      left: 0;
      right: 0;
      top: auto;
      bottom: 80px;
      transform: none;
    }
  }
  .slick-prev {
    left: 20px;
    ${theme.breakpoints.up('lg')} {
      transform: translateX(-40px);
    }
  }
  .slick-next {
    right: 20px;
    ${theme.breakpoints.up('lg')} {
      transform: translateX(40px);
    }
  }
`

const BannerSlider = ({
  items,
  settings,
  hTag
}) => {

  const sliderRef = useRef(null);
  const [loaded, setLoaded] = useState(false);

  const dealerSettings = process.env.DEALER_SITE ? {
    autoplay: items.length > 1 ? true : false,
    autoplaySpeed: 5000
  } : {}

  const slickSettings = {
    speed: 500,
    beforeChange: (oldIndex, newIndex) => resetVid(oldIndex, newIndex),
    ...settings,
    ...dealerSettings
  }

  const resetVid = (oldIndex, newIndex) => {

    const oldRef = videoReferences[oldIndex];
    const newRef = videoReferences[newIndex];

    if (oldRef && oldRef.current) {
      // Wait until transition complete
      setTimeout(() => { 
        oldRef.current.currentTime = 0;
        oldRef.current.pause();
      }, slickSettings.speed || 500);
    }

    if (newRef && newRef.current) {
      newRef.current.play();
    }
  }

  return (
  	<Container loaded={loaded ? 1 : 0}>
      <Slider
        forwardedRef={sliderRef}
        showUnloaded={true}
        settings={slickSettings}
        onInit={() => setLoaded(true)}
      >
        {items.map((item, index) => (
          <Banner
            key={index}
            videoRef={getOrCreateRef(index, videoReferences)}
            slider={true}
            {...item}
            hTag={hTag}
          />
        ))}
      </Slider>
    </Container>
  )
}
  
BannerSlider.propTypes = {
  items: propTypes.array.isRequired,
  settings: propTypes.object
}
  
BannerSlider.defaultProps = {
  settings: {}
}
  
export default BannerSlider
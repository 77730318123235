import React, { useEffect, useState, memo } from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {Grid, Box} from '@material-ui/core';
import produce from "immer";
import {get} from 'lodash';

import {theme} from '../themes/default-theme';
import BannerSlider from '../components/multimedia/BannerSlider';
import Banner from '../components/multimedia/Banner';
import Quicklinks from '../components/navigation/Quicklinks';
import ImageTextBannerSlider from '../components/multimedia/ImageTextBannerSlider';
import MultiContentBannerSlider from '../components/multimedia/MultiContentBannerSlider';
import MultiContentBannerSliderv2 from '../components/multimedia/MultiContentBannerSliderv2';

import BodyText from '../components/content/BodyText';
import YoutubeVideo from '../components/multimedia/YoutubeVideo';
import ListingText from '../components/content/ListingText';
import TabbedAccordion from '../components/ui/TabbedAccordion';
import TabbedGallery from '../components/multimedia/TabbedGallery';
import LogoListing from '../components/content/LogoListing';
import IconTextGrid from '../components/ui/IconTextGrid';
import IconTextGridv2 from '../components/content/IconTextGrid';
import ImageText from '../components/content/ImageText';
import IconCarousel from '../components/ui/IconTextCarousel';
import DynamicForm from '../components/forms/DynamicForm';
import Scripts from '../components/ui/Scripts';
// import Hydrate from './Hydrate';

// V2 component start
import HeroBanner from '../components/multimedia/NewHeroBanner'
import RangeCarousel from '../components/multimedia/RangeCarousel';
import ImageWithText from '../components/multimedia/ImageWithText';
// import QuicklinksV2 from '../components/navigation/Quicklinks'
import SocialGrid from '../components/multimedia/HomeSocialGrid';
import ModelGrid from '../components/navigation/ModelGrid';
import EnrichedImageGallery from '../components/multimedia/EnrichedImageGallery'
import CalculatorBanner from '../components/multimedia/CalculatorBanner';
import AvailableStock from '../components/multimedia/AvailableStock';

// V2 component end


const BannerSliderContainer = styled.div`

  // ${props => props.negativeMargin && `
  //   ${theme.breakpoints.up('sm')} {
  //     margin-top: -75px;
  //   }
  //   ${theme.breakpoints.up('md')} {
  //     margin-top: -81px;
  //   }
  // `}

  ${props => props.top === true ? `
    ${props.margin === 1 && `
      margin-bottom: 40px;
    `}

    ${theme.breakpoints.up('md')} {
      
      ${props.margin === 1 && `
        margin-bottom: 60px;
      `}
    }

    ${theme.breakpoints.up('lg')} {
      ${props.margin === 1 && `
        margin-bottom: 120px;
      `}
    }
  ` : `
    margin: 40px auto;

    ${theme.breakpoints.up('md')} {
      margin: 60px auto;
    }

    ${theme.breakpoints.up('lg')} {
      margin: 120px auto;
    }
  `}

  ${props => props.collapseTop && `
    margin-top: 0 !important;
  `}
  ${props => props.collapseBottom && `
    margin-bottom: 0 !important;
  `}
`

const QuicklinksContainer = styled.div`
  margin: 35px auto;

  ${theme.breakpoints.up('md')} {
    margin: 40px auto;
    max-width: 1254px;
  }

  ${theme.breakpoints.up('lg')} {
    margin: 120px auto;
  }
`

const MultiContentBannerSliderContainer = styled.div`
  margin: 35px 0;

  ${theme.breakpoints.up('md')} {
    margin: 40px 0;
  }

  ${theme.breakpoints.up('lg')} {
    margin: 90px 0;
  }
`
const ScriptContainer = styled.div`
     margin: 35px auto;
     width: 100%;
     max-width: 1254px;
    ${theme.breakpoints.up('md')} {
      margin: 40px auto;
    }

    ${theme.breakpoints.up('lg')} {
      margin: 90px auto;
    }
`
const Container = styled.div`
  margin: 35px auto;

  ${props => props.maxWidth && `
    max-width: ${props.maxWidth};
  `}

  ${theme.breakpoints.up('md')} {
    margin: 40px auto;
  }

  ${theme.breakpoints.up('lg')} {
    margin: 90px auto;
  }

  ${props => props.margintop === 0 && `
    margin-top: 0 !important;
  `}
  ${props => props.collapseBottom && `
    margin-bottom: 0!important;
  `}
`

const GreyContainer = styled.div `
  background-color: ${theme.palette.lightGrey};
  margin: 35px 0;
  padding: 35px 0;

  ${theme.breakpoints.up('md')} {
    margin: 40px 0;
    padding: 40px 0;
  }

  ${theme.breakpoints.up('lg')} {
    margin: 120px 0;
    padding: 120px 0;
  }

  ${props => props.collapseBottom && `
    margin-bottom: 0!important;
  `}
`

const ContentBuilder = ({data, onCtaClick, dealerCb, dealerHb, swapDealerQuicklinks, marginBottom, topOfDealerPage, marginTop, uri, countryProps}) => {
  console.log('ContentBuilder', data, countryProps);
  const [cbData, setCbData] = useState(data);
  const [dealerCbData, setDealerCbData] = useState(false);

  useEffect(() => {

    if (dealerCb) {
      
      try {
        const cb = document.getElementById('staticly-dynamics-component-ContentBuilder');
        const jsonData = window.staticly.data && window.staticly.data.ContentBuilder ? window.staticly.data.ContentBuilder : null

        if (!get(jsonData, 'data', null)) {
          cb && cb.remove();
          return;
        }
        
        if (jsonData) {
          // if dealerHb, merge data here
          const firstDataItem = get(jsonData, 'data[0]', {});

          if (dealerHb && firstDataItem.type === 'bannerSlider') {
            // merge
            const nextState = produce(jsonData.data, draftState => {
              draftState[0].data = dealerHb.concat(draftState[0].data)
            })
            setCbData(nextState)
          } else {
            if (dealerHb) {
              const newState = produce(jsonData.data, draftState => {
                draftState.unshift({
                  type: 'bannerSlider',
                  data: dealerHb
                })
              })
              setCbData(newState)
            } else {
              setCbData(jsonData.data)
            }
          }
          setDealerCbData(true)
          cb && cb.remove();
        }
      } catch {
        // do nothing
      }

    }

  }, [])

  const hbString = `{{#staticlyDynamic data from="template.data.contentBuilderItems" to="data" id="ContentBuilder" component="src/containers/ContentBuilder.js"}}{{/staticlyDynamic}}`
  
  const _cbData = dealerCb ? cbData : data // fix for quicklinks not working on HQ site.


    return (
      <>

        {!process.browser && dealerCb &&
          <>
            <div dangerouslySetInnerHTML={{__html: hbString}}>
            </div>
          </>
        }

        {(dealerCbData || !dealerCb) && _cbData && typeof(_cbData) === 'object' && _cbData.length > 0 ? _cbData.map((item, index) => {
          switch(item.type) {
            case 'heroBanner_v2_2023' :
            return (
              <BannerSliderContainer key={index} top={index === 0 ? true : false} negativeMargin={dealerCb && index === 0 && topOfDealerPage} marginBottom={marginBottom === false && index === cbData.length ? 0 : 1} collapseTop={item.collapseTop} collapseBottom={item.collapseBottom} >
                <HeroBanner
                  banners={item.data}
                  hTag={item.hTag}
                />
              </BannerSliderContainer>
            )
          }
          switch(item.type) {
            case 'calculatorBanner' :
              return (countryProps && countryProps.countryCode.toLowerCase() === 'au') && (
                <Container key={index}>
                  <CalculatorBanner
                    {...item.data}
                    hTag={item.hTag}
                  />
                </Container>
              )
          }
          switch(item.type) {
            case 'enrichedImageGallery_v2_2023' :
            return (
              <Container key={index}>
                <EnrichedImageGallery
                  {...item.data}
                  hTag={item.hTag}
                />
              </Container>
            )
          }
          switch(item.type) {
            case 'rangeCarousel' :
            return (
              <Container key={index}>
                <RangeCarousel
                  {...item.data}
                  hTag={item.hTag}
                />
              </Container>
            )
          }
          switch(item.type) {
            case 'imageWithText_v2_2023' :
            return (
              <Container key={index}>
                <ImageWithText
                  {...item.data}
                  hTag={item.hTag}
                />
              </Container>
            )
          }
          // switch(item.type) {
          //   case 'quicklinksV2' :
          //   return (
          //     <Container key={index}>
          //       <QuicklinksV2
          //         {...item.data}
          //       />
          //     </Container>
          //   )
          // }
          switch(item.type) {
            case 'multiContentBanner_v2_2023' :
            return (
              <Container key={index}>
                <MultiContentBannerSliderv2
                  {...item.data}
                  hTag={item.hTag}
                />
              </Container>
            )
          }
          switch(item.type) {
            case 'socialGrid' :
            return (
              <Container key={index}>
                <SocialGrid
                  {...item.data}
                  hTag={item.hTag}
                />
              </Container>
            )
          }
          switch(item.type) {
            case 'modelGrid' :
            return (
              <Container key={index}>
                <ModelGrid
                  {...item.data}
                  hTag={item.hTag}
                />
              </Container>
            )
          }
          switch(item.type) {
            case 'bannerSingle' :
            return (
              <BannerSliderContainer key={index} top={index === 0 ? true : false} negativeMargin={dealerCb && index === 0 && topOfDealerPage} marginBottom={marginBottom === false && index === cbData.length ? 0 : 1}>
                <Banner
                  priority={index === 0 ? 'high' : undefined}
                  {...item.data}
                  ctaPosition="top"
                  hTag={item.hTag}
                />
              </BannerSliderContainer>
            )
          }
          switch(item.type) {
            case 'ctaBanner' :
            return (
              <BannerSliderContainer key={index} top={index === 0 ? true : false} negativeMargin={dealerCb && index === 0 && topOfDealerPage} marginBottom={marginBottom === false && index === cbData.length ? 0 : 1}>
                <Banner
                  {...item.data}
                  onCtaClick={(item) => onCtaClick('ctaBanner', item)}
                  inline={true}
                  hTag={item.hTag}
                />
              </BannerSliderContainer>
            )
          }
          switch(item.type) {
            case 'bannerSlider' :
            return (
              <BannerSliderContainer key={index} top={index === 0 ? true : false} negativeMargin={dealerCb && index === 0 && topOfDealerPage} marginBottom={marginBottom === false && index === cbData.length ? 0 : 1}>
                <BannerSlider 
                  items={item.data.map(item => {
                    const type = item.type ? item.type : 'largeBanner'
                    return {
                      ...item,
                      type
                    }
                  })}
                  hTag={item.hTag}
                />
              </BannerSliderContainer>
            )
          }
          switch(item.type) {
            case 'quicklinks' :
            return (
              <QuicklinksContainer key={index}>
                <Quicklinks
                  heading={item.data.heading}
                  data={item.data.items}
                  dealerComponent={swapDealerQuicklinks}
                  hTag={item.hTag}
                />
              </QuicklinksContainer>
            )
          }
          switch(item.type) {
            case 'imageTextBannerSlider' :
            return (
              <MultiContentBannerSliderContainer key={index}>
                <ImageTextBannerSlider
                  heading={item.data.heading}
                  items={item.data.items}
                  hTag={item.hTag}
                />
              </MultiContentBannerSliderContainer>
            )
          }
          switch(item.type) {
            case 'multiContentBannerSlider' :
            return (
              <MultiContentBannerSliderContainer key={index}>
                <MultiContentBannerSlider
                  heading={item.data.heading}
                  items={item.data.items}
                  hTag={item.hTag}
                />
              </MultiContentBannerSliderContainer>
            )
          }
          switch(item.type) {
            case 'bodyText' :
            return (
              <Container key={index} maxWidth="812px">
                <BodyText
                  headerType={"h3"}
                  headerProps={{weight: 800}}
                  title={item.data.title}
                  content={item.data.content}
                  ctas={item.data.ctas}
                  hTag={item.hTag}
                />
              </Container>
            )
          }
          switch(item.type) {
            case 'inpageVideo' :
            return (
              <Container key={index} maxWidth="930px">
                <Grid container className={'hasPadding'} spacing={0} direction={'row'} wrap="nowrap" justify={'space-between'} align={'center'}>
                  <Grid item xs={12}>
                    <Box px={2}>
                      <YoutubeVideo
                        url={item.data}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            )
          }
          switch(item.type) {
            case 'listingText' :
            return (
              <Container key={index}>
                <ListingText
                  {...item.data}
                  hTag={item.hTag}
                />
              </Container>
            )
          }
          switch(item.type) {
            case 'faqs' :
            return (
              <Container key={index}>
                <TabbedAccordion
                  showTitle={true}
                  items={item.data}
                  hTag={item.hTag}
                />
              </Container>
            )
          }
          switch(item.type) {
            case 'tabbedGallery' :
            return (
              <Container key={index} margintop={(index === 0 && marginTop === false) ? 0 : 1}>
                <TabbedGallery
                  data={item}
                  heading={item.data.title}
                  items={item.data.items}
                  hTag={item.hTag}
                />
              </Container>
            )
          }
          switch(item.type) {
            case 'logos' :
            return (
              <Container key={index}>
                <LogoListing
                  title={item.data.title}
                  items={item.data.items}
                  hTag={item.hTag}
                />
              </Container>
            )
          }
          switch(item.type) {
            case 'iconText' :
            return (
              <Container key={index}>
                <IconTextGrid
                  data={item.data}
                  hTag={item.hTag}
                />
              </Container>
            )
          }
          switch(item.type) {
            case 'iconTextGrid' :
            return (
              <Container key={index} collapseBottom={(index + 1) === _cbData.length}
              >
                {item.data.background === 'dark' ? 
                  <GreyContainer
                    collapseBottom={(index + 1) === _cbData.length}
                  >
                    <IconTextGridv2
                      {...item.data}
                      hTag={item.hTag}
                    />
                  </GreyContainer>
                :
                  <IconTextGridv2
                    {...item.data}
                    hTag={item.hTag}
                  />
                }
              </Container>
            )
          }
          switch(item.type) {
            case 'imageText' :
            return (
              <Container key={index}>
                <ImageText
                  {...item.data}
                  hTag={item.hTag}
                />
              </Container>
            )
          }

          switch(item.type) {
            case 'iconCarousel' :
            return (
              <Container key={index}>
                <GreyContainer
                >
                  <IconCarousel
                    {...item.data}
                    hTag={item.hTag}
                  />
                </GreyContainer>
              </Container>
            )
          }

          switch(item.type) {
            case 'staticlyForm' :
            return (
              <Container key={index}>
                <DynamicForm
                  {...item.data}
                  uri={uri}
                />
              </Container>
            )
          }

          switch(item.type) {
            case 'script' :
            return (
              <ScriptContainer key={index}>
                <Scripts
                  title={item.data.title}
                  html={item.data.scriptCode}
                  hTag={item.hTag}
                />
              </ScriptContainer>
            )
          }

          switch(item.type) {
            case 'availableStock' :
            return (
              <Container key={index}>
                <AvailableStock
                  {...item.data}
                  hTag={item.hTag}
                  rates={item.rates}
                />
              </Container>
            )
          }

        }) : null
        }
      </>
    )
  // }
}
  
ContentBuilder.propTypes = {
  data: propTypes.array.isRequired,
  onCtaClick: propTypes.func,
  swapDealerQuicklinks: propTypes.bool,
  dealerCb: propTypes.bool,
  marginBottom: propTypes.bool,
  marginTop: propTypes.bool,
  topOfDealerPage: propTypes.bool,
  uri: propTypes.string
}
  
ContentBuilder.defaultProps = {
  data: [],
  onCtaClick: () => {},
  swapDealerQuicklinks: false,
  dealerCb: false,
  marginBottom: true,
  marginTop: true,
  topOfDealerPage: false,
  uri: ''
}

// const Render = () => {
//   if (process.browser) {
//     return ReactDOM.hydrate(<ContentBuilder />, document.getElementById('contentbuilder'));
//   } else {
//     return ContentBuilder
//   }
// }

export default memo(ContentBuilder)
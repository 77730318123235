import React, {useState} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {Box as MuiBox} from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import {ReactSVG} from 'react-svg';

import enlargeSvg from '../../../assets/icons/enlarge.svg';
import decreaseSvg from '../../../assets/icons/decrease.svg';
import topoPng from '../../../assets/topo-vertical.png';
import {RespImg, Icon} from '../../ui';
import {theme} from '../../../themes/default-theme';
import Text from '../../typography';

const Container = styled.div`
  overflow: hidden;
  position: relative;
  border-top-left-radius: 50px;

  ${theme.breakpoints.up("md")} {
    min-height: 650px;
    max-width: ${theme.containers.full.width};
    margin: 0 auto;
    border-top-left-radius: 0;
    border-bottom-right-radius: 100px;
  }

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.65;
    left: 0;
    top: 0;
    position: absolute;
    z-index: 1;
    transition: opacity 200ms ease-in-out;

    ${props => !props.bg && `
      opacity: 0;

      ${theme.breakpoints.up("md")} {
        opacity: 0.65;
      }
    `}
  }

`

const BgAssetWrapper = styled.div`
  position: relative;
  overflow: hidden;
  min-height: 320px;

  ${theme.breakpoints.up("md")} {
    border-bottom-right-radius: 100px;
    min-height: 650px;
  }

  picture,
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    height: auto;
    max-height: none;
    max-width: none;
    min-height: 100%;
    min-width: 100%;
    transform: translate(-50%, -50%);

    ${theme.breakpoints.up("lg")} {
      position: static;
      top: 0;
      left: 0;
      transform: none;
      width: 100%;
      height: auto;
    }
  }
`

const Wrapper = styled.div`

`

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  opacity: 1;
  transition: opacity 200ms ease-in-out;

  ${props => props.mobileDisplay === false && `
    opacity: 0;
  `}

  ${theme.breakpoints.up("md")} {
    padding-left: 80px;
    opacity: 1;
  }
`

const BoxContent = styled.div`
  margin-top: 20px;
  color: white;
  
  h2 {
    text-align: center;
  }

  ${theme.breakpoints.up("md")} {
    font-size: 16px;
    margin-top: 100px;
    max-width: 420px;
    h2 {
      text-align: left;
    }
  }
  
`

const Topo = styled.div`
  display: none;
  ${theme.breakpoints.up("md")} {
    display: block;
    width: 80px;
    height: 424px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    overflow: hidden;
    background-image: url(${topoPng});
    background-size: 80px 574px;
  }
`

const Enlarge = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;
  button {
    width: 34px;
    height: 34px;
  }
  svg {
    width: 16px;
    max-height: 16px;
    height: auto;

    ${props => props.white && `
      background-color: white;
    `}
  }

  ${theme.breakpoints.up("md")} {
    display: none;
  }
`

const ImageTextBanner = ({
  bgImage,
  content
}) => {

  const [mobileTextOverlay, setMobileTextOverlay] = useState(true);

  return (
    <Wrapper>
      <Container bg={mobileTextOverlay}>
        <Topo />
        <Enlarge white={!mobileTextOverlay}>
          <Icon onClick={() => setMobileTextOverlay(!mobileTextOverlay)} gallery skyBlue>
            <ReactSVG src={mobileTextOverlay ? enlargeSvg : decreaseSvg} />
          </Icon>
        </Enlarge>
        <BgAssetWrapper>
          {bgImage &&
            <RespImg
              {...bgImage}
            />
          }
        </BgAssetWrapper>
        {content &&
          <Content mobileDisplay={mobileTextOverlay}>
            <BoxContent>
              <MuiBox px={2}>
                <Text variant="h2" colour="white" uppercase>{content.title}</Text>
                {content.text && <div>{ReactHtmlParser(content.text)}</div> }
              </MuiBox>
            </BoxContent>
          </Content>
        }
      </Container>
    </Wrapper>
  )
}
  
ImageTextBanner.propTypes = {
  bgImage: propTypes.object,
  content: propTypes.shape({
    title: propTypes.string,
    text: propTypes.string,
  })
}
  
ImageTextBanner.defaultProps = {
  content: {}
}
  
export default ImageTextBanner
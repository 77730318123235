import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import withWidth from '@material-ui/core/withWidth';
import { chunk } from 'lodash';

import {RespImg} from '../../ui';
import {theme} from '../../../themes/default-theme';
import instagram from '../../../assets/icons/instagram.svg';
import Text from '../../typography'
import { Grid, Box } from '@material-ui/core';


const Wrapper = styled.div`
  margin-bottom: 105px;
  margin-top: 100px;
  
  ${theme.breakpoints.up('md')} {
    margin-bottom: 120px;
    margin-top: 120px;
  }
  ${theme.breakpoints.up('lg')} {
    margin-bottom: 140px;
    margin-top: 140px;
  }
    
  .MuiTypography-h3,
  h3 {
    margin-bottom: 25px;

    ${theme.breakpoints.up('lg')} {
      margin-bottom: 40px;
    }
  }
`

const Container = styled.div`
  width: 100%;
  max-width: 1397px;
  margin: auto;
  display: flex;
  gap: 8px;

  > div:first-child,
  > div:last-child {
    > a:first-child {
      width: 100%;
      padding-top: 100%;
    }
  }

  > div:nth-child(2) {
    > a:last-child {
      width: 100% !important;
      padding-top: 100%;
    }
  }

  ${theme.breakpoints.down("sm")} {
    > div:first-child,
    > div:last-child {
      display: none;
    }
  }
`

const ImageRowContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 8px;

  ${theme.breakpoints.down("sm")} {
    gap: 6px;
  }
`

const ImageContainer = styled.a`
  position: relative;
  width: 49%;
  transition: all 300ms ease;
  cursor: pointer;
  height: 0;
  padding-top: 49%;

  ${theme.breakpoints.only("md")} {
    width: 48.5%;
    padding-top: 48.5%;
  }

  &:after {
    content: '';
    width: 19.62px;
    height: 19.62px;
    background-image: url(${instagram});
    position: absolute;
    right: 9.38px;
    bottom: 9.38px;
  }

  &:hover {
    transform: scale(1.01);
  }

  picture,
  img {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 50%;
    object-fit: cover;
    transform: translateY(-50%);
  }
`

const HomeSocialGrid = (props) => {

  const {
    // data
    countryCode,
    hashtag,
    hTag
  } = props

  

  const [data, setData] = useState([]);
  
  useEffect(() => {

    const region = countryCode && countryCode.toLowerCase() === 'nz' ? 'jayco.co.nz' : 'jayco.com.au';

    let instagramUrl = `https://staticly-social.jayco-com-au.au1.staticlyproject.com/${region}/instagram.json`;

    async function fetchInstagram(url) {
      let res = await fetch(url ? url : 'https://staticly-social.jayco-com-au.au1.staticlyproject.com/jayco.com.au/instagram.json')
      const response = await res.json()
      const refactoredResponse = response.items.map(item => {
        return {
          ...item,
          image: {
            alt: '',
            sizes: {
              default: {
                jpg: item.image,
                width: 275,
                height: 278
              }
            }
          }
        }
      })
      setData(refactoredResponse)
    }
      
    fetchInstagram(instagramUrl);

  }, []);



  const filteredData = [...Array(3).keys()].map(c => data.slice(0, 9).filter((_, i) => i % 3 === c));

  const chunkData = chunk(data.slice(0, 9), 3)

  // console.log('countryCode', {countryCode});

  return (

  <Wrapper>
      <Grid container className="hasPadding">
        <Grid item xs={12}>
          <Box px={2}>
            <Text variant="h3" element={hTag ? hTag : 'h3'} weight={800} size="extraLarge" colour="charcoal" align={"center"} uppercase={true}>Explore {hashtag ? `#${hashtag}` : countryCode && countryCode.toLowerCase() === 'nz' ? '#JAYCONEWZEALAND' : '#JAYCOAUSTRALIA'}</Text>
          
            <Container>
                {filteredData &&
                  filteredData.map(function(item, i) {
                    return (
                      <ImageRowContainer key={i}>
                        {item.map(function(child, i) {
                          return (
                            <ImageContainer
                              key={i}
                              href={child.link || child.href}
                              target={'_blank'}
                            >
                              <RespImg 
                                {...child.image}
                                lazyLoad={false}
                              />
                            </ImageContainer>
                          )
                        })}
                      </ImageRowContainer>
                    )
                  })
                }
            </Container>
          </Box>
        </Grid>
      </Grid>
    </Wrapper>
  )

}

HomeSocialGrid.propTypes = {
};

HomeSocialGrid.defaultProps = {
}
  
export default withWidth()(HomeSocialGrid)

import React from 'react';
import PropTypes from 'prop-types';
import {Tabs, Tab} from '@material-ui/core';
import styled from 'styled-components';
import {theme} from '../../../themes/default-theme';

const TabsContainer = styled(props => {
  return <Tabs {...props} />
})`
  ${theme.breakpoints.down("md")} {
    position: relative;
    &:after {
      content: '';
      background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
      position: absolute;
      right: 0;
      top: 0;
      width: 30px;
      height: 100%;
    }

    .MuiTabs-scroller button:last-child {
      padding-right: 30px;
    }
  }
  &:hover: {
    outline: 'none',
  }
  & .MuiTabs-indicator {
    height: 4px;
    background-color: ${theme.palette.vividBlue};
  }
  .MuiTabs-scroller {
    flex: initial;
    margin: 0 auto;
  }

  .MuiTabs-scrollable {

  }
//   & .MuiTabs-indicator {
//     background: none;
//   }
`;

const TabSelect = styled(props => {
  return <Tab {...props} classes={{selected: 'selected'}} />
})`
  & {
    font-size: 15px;
    font-weight: 800;
    line-height: 1.2;
    color: ${theme.palette.gunmentalGrey};
    // background : ${theme.palette.white};
    text-transform: capitalize;
    // font-weight: 400;
    // border: 1px solid ${theme.palette.middleGrey};
    ${theme.breakpoints.down("sm")} {
      font-size: 12px;
    }
    opacity: 1;

    border-bottom: 4px solid #D9D9D9;

    &:hover {
      color: ${theme.palette.charcoal};
    }
  }
  &.selected {
    color: ${theme.palette.vividBlue};
    // background-color: ${theme.palette.vividBlue};
    // border: 1px solid ${theme.palette.vividBlue};
  }
  &:first-child {
    // border-top-left-radius: 14px;
  }
  &:last-child {
    // border-bottom-right-radius: 14px;
  }
`;

export const TabSelection = props => {
  const [value, setValue] = React.useState(props.activeIndex);
  const {data, variant} = props;

  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.onChange(newValue);
  };

  return (
    <TabsContainer
      value={value}
      onChange={handleChange}
      variant={variant}
      //centered={true}
    >
      {data && data.map(function(tab, index) {
        return (
          <TabSelect
            key={tab.id || index}
            label={tab.title} 
          />
        )
      })}
    </TabsContainer>
  );
}

TabSelection.propTypes = {
  data: PropTypes.array,
  variant: PropTypes.string,
  onChange: PropTypes.func,
  activeIndex: PropTypes.number
};

TabSelection.defaultProps = {
  variant: 'scrollable',
  onChange: () => {},
  activeIndex: 0
};

export default TabSelection;
import React, {useState, useRef} from 'react';
import propTypes from 'prop-types';
// import Masonry from 'react-masonry-component';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import {makeStyles} from '@material-ui/core/styles';

import Modal from '../../ui/Modal';
import Slider from '../Slider';
import GalleryThumbnail from '../GalleryThumbnail';
import GalleryOverlay from '../GalleryOverlay';
import {theme} from '../../../themes/default-theme';


const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: '1400px',
    margin: '0 auto',

    [theme.breakpoints.up('md')]: {
      padding: 11,
    }

  },
  ul: {
    padding: 0,
    listStyle: 'none',
  },
  li: {
    // width: '50%',
    padding: 5,

    [theme.breakpoints.up('md')]: {
      // width: '33.3333%',
      padding: 10,
    },

    // [theme.breakpoints.up('lg')]: {
    //   width: '25%',
    // }
  },
  modalContainer: {
    width: '100%',
    margin: '0 auto',

    '& .slick-prev, & .slick-next': {
      position: 'absolute',
      zIndex: 2,
      bottom: '-65px',
      marginBottom: 20,
      [theme.breakpoints.up('md')]: {
        bottom: 25,
        marginBottom: 0,
        right: '47.1428%;',
      }
    },

    '& .slick-active': {
      zIndex: 3,
    },

    '& .slick-prev': {
      transform: 'translateX(18px)',
      [theme.breakpoints.up('md')]: {
        transform: 'translateX(90px)',
      },
      [theme.breakpoints.up('lg')]: {
        transform: 'translateX(130px)',
      }
    },
    '& .slick-next': {
      transform: 'translateX(68px)',
      [theme.breakpoints.up('md')]: {
        transform: 'translateX(150px)',
      },
      [theme.breakpoints.up('lg')]: {
        transform: 'translateX(190px)',
      }
    },

    [theme.breakpoints.down('sm')]: {
      // paddingBottom: 100,
      // '& .slick-track': {
      //   display: 'flex',
      //   flexDirection: 'row',
      //   alignItems: 'stretch', 
      //   '& .slick-slide': {
      //     height: 'auto',
      //     backgroundColor: theme.palette.white,
      //     borderBottomRightRadius: 50,
      //     borderTopLeftRadius: 50,
      //   }
      // }
    }
  },
  modalOverlay: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    }
  },
  modalInner: {
    width: '100%',
    display: 'inline-block',
    position: 'relative',
    maxWidth: 1400,
  },
  modalClose: {
    bottom: '-64px',
    left: 118,
    marginBottom: '20px',
    position: 'absolute',

    [theme.breakpoints.up('md')]: {
      top: 20,
      bottom: 'auto',
      left: 'auto',
      right: 20,
      marginBottom: 0,
    }
  }
}));

const masonryOptions = {
  transitionDuration: 200
};

const Gallery = ({
  items
}) => {
  const [modal, openModal] = useState(false);
  const [modalIndex, setModalIndex] = useState(0);
  const sliderRef = useRef(null);

  const handleItemClick = (index) => {
    openModal(true);
    setModalIndex(index);
  }

  const classes = useStyles();
  return (
    <div>
      <Modal
        isOpen={modal}
        onClose={() => openModal(false)}
        overlayClassname={classes.modalOverlay}
        innerClassname={classes.modalInner}
        closeClassname={classes.modalClose}
      >
        <div className={classes.modalContainer}>
          <Slider
            forwardedRef={sliderRef}
            settings={{
              initialSlide: modalIndex,
              adaptiveHeight: true
            }}
          >
            {items.map((item, index) =>  (
              <div key={item.overlay.title}>
                <GalleryOverlay 
                  {...item.overlay}
                  imgCover={true}
                />
              </div>
            ))}
          </Slider>
        </div>
      </Modal>

      <div className={classes.container}>
        <ResponsiveMasonry
          columnsCountBreakPoints={{
            [theme.breakpoints.values.sm]: 2,
            [theme.breakpoints.values.md]: 3,
            [theme.breakpoints.values.lg]: 4
          }}
        >
          <Masonry
            className={classes.ul} // default ''
          >
            {items && items.map((item, index) => (
              <div key={item.title} className={classes.li}>
                <GalleryThumbnail
                  onClick={() => handleItemClick(index)}
                  {...item}
                />
              </div>
            ))}
          </Masonry>
        </ResponsiveMasonry>

        {/* <Masonry
          className={classes.ul} // default ''
          elementType={'div'} // default 'div'
          options={masonryOptions} // default {}
          disableImagesLoaded={false} // default false
          updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
          imagesLoadedOptions={{}} // default {}
        >
          {items && items.map((item, index) => (
            <div key={item.title} className={classes.li}>
              <GalleryThumbnail
                onClick={() => handleItemClick(index)}
                {...item}
              />
            </div>
          ))}
        </Masonry> */}
      </div>
      
    </div>
  )
}
  
Gallery.propTypes = {
  items: propTypes.array.isRequired
}
  
Gallery.defaultProps = {
  
}
  
export default Gallery
import React, {useState, useEffect} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {Grid, Box} from '@material-ui/core';
import {theme} from '../../../themes/default-theme';
import Text from '../../../components/typography';
import Checkbox from '../../../components/forms/Checkbox';
import SeriesCard from '../../../components/navigation/NewMainNav/SeriesCard';
import BannerPopup from '../../../components/ui/BannerPopup';
import withWidth from '../../../components/hoc/withWidth';
import {findIndex} from 'lodash';
import produce from 'immer';
import qs from 'qs';
import dlPush from '../../../util/dlPush';
import {sellsMotorhomes, sellsCampervans} from '../../../util/filterDealerMotorHomes';

import {isWidthUp} from '@material-ui/core/withWidth';

const Wrapper = styled.div`
  width:100%;
`

const Intro = styled.div`
  .MuiTypography-h3,
  h3 {
    font-weight:800;
    margin-bottom:23px;
  }
`
const SeriesCardContainer = styled.div`  
  width:100%;
  text-align:center;
  display:flex;
  align-items:left;
  justify-content:flex-start;
  flex-direction:row;
  flex-wrap:wrap;
  max-width: 1036px;
  padding: 0 10px;
  ${props => props.length <= 2 && `
    justify-content: center;
  `}
`

const SeriesCardItem = styled.div`
  width:50%;
  padding: 0 10px;
  ${theme.breakpoints.up('lg')} { 
    width:33.33%;
  }
  ${theme.breakpoints.down('sm')} {
    width:50%;
    a {
      display:inline-block;
      width:auto;
    }
  }
  div {
    padding:0;
    justify-content:center;
  }
  h4 {
    text-align:center;
  }
  picture, img {
    width: 275px;
    ${theme.breakpoints.down('sm')} {
      width:100%;
      max-width:275px;
    }
  }

  ${theme.breakpoints.down('md')} {
    > div {
      max-width: 275px;
      margin: 0 auto;
    }
  }
  ${theme.breakpoints.up('md')} {
    > div {
      height: calc(100% - 62px);
    }
  }

  ${theme.breakpoints.down('sm')} {
    > div {
      height: calc(100% - 44px);
    }
  }
`

const CompareContainer = styled.div`
  width:100%;
  text-align:center;
  margin-top:-10px;
  margin-bottom:10px;
  ${theme.breakpoints.down('sm')} {
    margin-bottom:0;
    margin-top:0;
  }
`
const ModelGrid = (props) => {
    const {
        title,
        models,
        hTag
    } = props
    const maxToCompare = isWidthUp('md', props.width) ? 3 : 2;
    const minToCompare = 2;
    const [curWidth, updateCurWidth] = useState(props.width);
    const [compareItems, setCompareItems] = useState([]);
    const [compareUrl, updateCompareUrl] = useState('');

    useEffect(() => {
      // If width changes from md to sm/xs & compare items length is at max then clear items (2 items for sm, 3 for md up)
      if (
        ((compareItems.length === 3) && curWidth !== 'sm' && curWidth !== 'xs') && (props.width === 'sm' || props.width === 'xs')
      ) {
        setCompareItems([])
      }
      updateCurWidth(props.width);
    }, [props.width]);

    const updateParams = (compare) => {
      const compareData = compare.map(item => { return {
        range: item.rangeId,
        series: item.seriesId,
        spec: item.spec,
        model: item.model,
      }})
      const param = qs.stringify({items: compareData});
      updateCompareUrl(param)
    }

    const handleCompare = (item, checked) => {

      console.log('handleCompare', item, checked);
      if (!item.compareValues.rangeId) {
        return
      }
      if (checked && compareItems.length < maxToCompare) {
        // add
        const newState = produce(compareItems, draft => {
          draft.push(item.compareValues);
        });
        setCompareItems(newState);
        updateParams(newState);
        item.title && dlPush({
          'event': 'jaycoEvent',
          'eventAction': 'CTA Click - Our Range - Compare',
          'eventCategory': 'Research',
          'eventLabel': item.title 
        })
      } else if (!checked) {
        // delete
        const newState = produce(compareItems, draft => {
          const compareIndex = findIndex(draft, {seriesId: item.compareValues.seriesId});
          draft.splice(compareIndex, 1);
        });
        setCompareItems(newState);
        updateParams(newState);
      }
    }

    const handleNextClick = () => {
      dlPush({
        'event': 'jaycoEvent',
        'eventAction': 'CTA Click - Our Range - Compare',
        'eventCategory': 'Research',
        'eventLabel': 'Next'
      })
    }

    console.log('ModelGrid props', props, compareItems);
    const _sellsCampervans = sellsCampervans();
    const _sellsMotorhomes = sellsMotorhomes();
    return (
      <>
        <Wrapper>
            <Grid container wrap="wrap" justifyContent={'center'} align={'center'}> 
                <Grid item xs={12} >
                    <Intro>
                        {title && 
                            <Text variant="h3" element={hTag ? hTag : 'h3'} colour="charcoal" uppercase align="center">{title}</Text>
                        }
                    </Intro>
                </Grid>
                <Grid item xs={12} >
                    <SeriesCardContainer length={models.length}>
                        {models && models.filter(item => {
                          if (process.env.DEALER_SITE) {
                            if (item.subTitle.toLowerCase().indexOf('campervan') !== -1 && !_sellsCampervans ) {
                              return false
                            }
                            if (item.subTitle.toLowerCase().indexOf('motorhome') !== -1 && !_sellsMotorhomes ) {
                              return false
                            }
                            return true
                          }
                          return true;
                        }).map((item, index) => {
                        return (
                            <SeriesCardItem key={item.title + index}>
                                <SeriesCard 
                                    href={item.href}
                                    title={item.title}
                                    subTitle={item.subTitle}
                                    //sleeps={item.sleeps}
                                    image={item.image}
                                    hasborder='false'
                                    hideSleeps={true}
                                />
                                <CompareContainer >
                                  <Checkbox
                                    label="Compare"
                                    name={item.title}
                                    disabled={compareItems.length >= maxToCompare && findIndex(compareItems, {seriesId: item.compareValues.seriesId}) === -1}
                                    onChange={(event) => handleCompare && handleCompare(item, event.target.checked)}
                                  />
                                </CompareContainer>
                            </SeriesCardItem>
                        )
                        })}
                    </SeriesCardContainer>
                </Grid>
            </Grid>
        </Wrapper>
        <BannerPopup 
          open={compareItems.length > 0}
          cta={{
            title: 'Next',
            href: `/compare?${compareUrl}`,
            disabled: compareItems.length >= minToCompare ? false : true
          }}
          onClick={handleNextClick}
          text={`Select a maximum of ${maxToCompare} to compare`}
        />
      </>
    )
}
    
ModelGrid.propTypes = {
}
    
ModelGrid.defaultProps = {
}
    
export default withWidth(ModelGrid)
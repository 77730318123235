import React, {useState, useEffect} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import topoPng from '../../../assets/topo-block-blue.png';

import {theme} from '../../../themes/default-theme';
import NextLink from '../../navigation/NextLink';
import Text from '../../typography';
import {Cta, RespImg} from '../../ui';
import refIds from '../../../util/refIds';

const Wrapper = styled.div`
  position: relative;
  max-width: 1600px;
  margin: 20px auto;
  ${theme.breakpoints.up('md')} {
    padding: 36px 0 0;
    margin: 0 auto;
  }
  ${theme.breakpoints.up('md')} {
    padding: 60px 0 0;
  }
`

const Topo = styled.div`
  display: none;
  ${theme.breakpoints.up("md")} {
    display: block;
    position: absolute;
    top: 0;
    ${props => props.imagePosition === 'right' ? 'right: 0' : 'left: 0'};
    z-index: 1;
    overflow: hidden;
    background-image: url(${topoPng});
    width: 204px;
    height: 138px;
    background-size: 204px 138px;
  }

  ${theme.breakpoints.up('lg')} {
    width: 405px;
    height: 226px;
    background-size: 405px 226px;
  }
`

const Container = styled.div`
  max-width: ${theme.containers.main.width};
  margin: 0 auto;
  padding: 0 ${theme.containers.main.gutter};
  position: relative;
  z-index: 2;
`

const Row = styled.div`
  ${theme.breakpoints.up('md')} {
    display: flex;
    flex-direction: ${props => props.imagePosition === 'right' ? 'row-reverse' : 'row'};
  }
`

const ImageCol = styled.div`
  margin-bottom: 20px;
  ${theme.breakpoints.up('md')} {
    width: 50%;
    margin-bottom: 0;
  }
  ${theme.breakpoints.up('lg')} {
    width: 55%;
  }
`
const ImageContainer = styled.div`
  overflow: hidden;
`

const TextContainer = styled.div`
  margin: 20px 0;
`

const ContentCol = styled.div`
  
  ${theme.breakpoints.up('md')} {
    margin-top: 20px;
    width: 50%;
    ${props => props.imagePosition === 'right' ? 'padding-right: 24px' : 'padding-left: 24px'};
  }
  ${theme.breakpoints.up('lg')} {
    margin-top: 33px;
    width: 45%;
    ${props => props.imagePosition === 'right' ? 'padding-right: 70px' : 'padding-left: 70px'};
  }

`

const CtasContainer = styled.div`

`

const CtaItem = styled.div`
  display: inline-block;
  margin-bottom: 10px;
  margin-right: 20px;
`

const ImageText = ({
  heading,
  description,
  imagePosition,
  ctas,
  image,
  hTag
}) => {

  const [refId, setRefId] = useState(null);

  useEffect(() => {
    if (process.env.DEALER_SITE) {
      const ref = (window.staticly.dealerData.website && refIds[`${window.staticly.dealerData.website}`]) ? refIds[`${window.staticly.dealerData.website}`] : '';
      setRefId(ref);
    }
  }, []);


  return (
    <Wrapper>
      <Topo imagePosition={imagePosition}/>
      <Container>
        <Row imagePosition={imagePosition}>
          <ImageCol>
            <ImageContainer imagePosition={imagePosition}>
              <RespImg 
                {...image}
              />
            </ImageContainer>
          </ImageCol>
          
          <ContentCol imagePosition={imagePosition}>
            <Text variant="h4" element={hTag ? hTag : 'h4'} colour="charcoal">{heading}</Text>
            <TextContainer>
              <Text variant="body1">{ReactHtmlParser(description)}</Text>
            </TextContainer>
            <CtasContainer>
              {ctas.map((cta, index) => (
                <CtaItem key={cta.href || index}>
                  {cta.href.toLowerCase().indexOf('refid') === -1 ?
                  <NextLink href={cta.href}>
                    <a>
                      <Cta component="span" outline={index === 1 ? true : false} inline>
                        {cta.title}
                      </Cta>
                    </a>
                  </NextLink>
                  :
                    <a href={refId ? `${cta.href.toLowerCase().split('?refid')[0]}?refid=${refId}` : cta.href}>
                      <Cta component="span" outline={index === 1 ? true : false} inline>
                        {cta.title}
                      </Cta>
                  </a>
                  }

                </CtaItem>
              ))}
            </CtasContainer>
          </ContentCol>
        </Row>
      </Container>
    </Wrapper>
  )
}
  
ImageText.propTypes = {
  heading: propTypes.string,
  description: propTypes.string,
  imagePosition: propTypes.string,
  ctas: propTypes.array,
  image: propTypes.object,
}
  
ImageText.defaultProps = {
  heading: null,
  discription: null,
  imagePosition: 'left',
  ctas: [],
  image: null
}
  
export default ImageText
import React, {useRef, useState, useEffect} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { isWidthUp } from '@material-ui/core/withWidth';
import {Grid, Box as MuiBox} from '@material-ui/core';
import withWidth from '../../../components/hoc/withWidth'

import {theme} from '../../../themes/default-theme';
import {RespImg} from '../../ui';
import ImageTextBanner from '../ImageTextBanner';
import Slider from '../Slider';
import {Text} from '../../typography';

const Heading = styled(props => {
  return <Text {...props} />
})`
  margin-bottom: 10px;
  text-align: center;
  ${theme.breakpoints.up("md")} {
    margin-bottom: 50px;
  }
`

const Wrapper = styled.div`
  position: relative;

  .slick-prev {
    position: absolute;
    right: 51%;
    bottom: 10%;
    z-index: 99;
    ${theme.breakpoints.down('sm')} {
      left: 38%;
    }
  }
  .slick-next {
    position: absolute;
    left: 51%;
    bottom: 10%;
    z-index: 99;
    ${theme.breakpoints.down('sm')} {
      right: 38%;
    }
  }

  ${theme.breakpoints.down('md')} {
    picture img {
      top: 0;
      width: 100%;
      object-fit: cover;
      transform: translate(-50%,0%);
    }
  }

  ${theme.breakpoints.down('sm')} {
    picture img {
      top: 0;
      position: relative;
      object-fit: cover;
      height: 100%;
    }
  }
`

const NavSlider = styled.div`
  max-width: 935px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  margin-top: 15px;
  padding: 0 ${theme.containers.main.gutter};

  ${theme.breakpoints.up('md')} {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10px;
    margin: auto;
    padding: 0 80px;
    overflow: visible;
  }

  picture {
    position: relative;
    border-top-right-radius: 20px;
    overflow: hidden;
    border: 2px solid transparent;

    &:before {
      content: '';
      background-color: ${theme.palette.charcoal};
      opacity: 0.7;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      transition: opacity 200ms ease-in-out;
    }
  }

  .slick-current {
    picture {
      border-color: ${theme.palette.yellow};
      &:before {
        opacity: 0;
      }
    }
  }

  .slick-slider {
    position: relative;

    ${theme.breakpoints.down('sm')} {
      margin-bottom: 50px;
      .slick-next,
      .slick-prev {
        position: absolute;
        bottom: -50px;
        margin: auto;
        left: 0;
        right: 0;
        background-color: ${theme.palette.skyBlue};

        &:hover svg {
          filter: brightness(500%);
        }
      }

      .slick-prev {
        transform: translateX(-25px);
      }
      .slick-next {
        transform: translateX(25px);
      }
    }

    ${theme.breakpoints.up('md')} {
      .slick-next,
      .slick-prev {
        position: absolute;
        top: 35px;
        background-color: rgba(255,255,255,0.4);
      }

      .slick-prev {
        left: -60px;
      }

      .slick-next {
        right: -60px;
      }
    }
  }
`

const NavSliderItem = styled.div`
  padding: 3px;
  text-align: center;
  outline: none;
  
`

const ImageTextBannerSlider = ({
  items,
  width,
  heading,
  hTag
}) => {

  const mainSlider = useRef(null);
  const navSlider = useRef(null);

  const [sliders, setSliders] = useState({});

  useEffect(() => {
    setSliders({
      mainSlider: mainSlider,
      navSlider: navSlider
    })
  },[])
  
  const mainSlickSettings = {
    arrows: true,
    speed: 500,
    asNavFor: sliders.navSlider && sliders.navSlider.current,
  }

  const tempSlickSettings = {
    arrows: true,
    speed: 500,
    asNavFor: sliders.navSlider && sliders.navSlider.current,
  }

  const navSlickSettings = {
    arrows: true,
    speed: 500,
    slidesToShow: items.length > 4 ? 5 : items.length,
    slidesToScroll: 1,
    fade: false,
    slide: true,
    centerMode: false,
    variableWidth: false,
    focusOnSelect: true,
    asNavFor:sliders.mainSlider && sliders.mainSlider.current,
    responsive: [
      {
        breakpoint: theme.breakpoints.width('md'),
        settings: {
          slidesToShow: items.length > 2 ? 3 : items.length
        }
      }
    ]
  }

  return (
    <Wrapper>
      {heading &&
        <Grid container spacing={0} direction={'row'} wrap="nowrap" justify={'space-between'} align={'flex-start'} >
          <Grid item xs={12}>
            <MuiBox px={2}>
              <Heading variant="h2" element={hTag ? hTag : 'h2'} size="extraLarge" colour="charcoal" uppercase={true}>{heading}</Heading>
            </MuiBox >
          </Grid>
        </Grid>
      }
      <Slider
        settings={mainSlickSettings}
        forwardedRef={mainSlider}
      >
        {items.map(item => (
          <ImageTextBanner 
            key={item.id}
            {...item}
          />
        ))}
      </Slider>
      <NavSlider>
        {/* <Slider
          settings={navSlickSettings}
          forwardedRef={navSlider}
        >
          {items.map(item => (
            <NavSliderItem key={item.id}>
              {item.content && 
                !isWidthUp('md', width) && <Text align="center" colour="black" variant="subTitle">{item.content.title}</Text>
              }
                <RespImg
                  {...item.thumbnail}
                />
              
              {item.content && 
                isWidthUp('md', width) && <Text align="center" colour="white" variant="subTitle">{item.content.title}</Text>
              }
              
            </NavSliderItem>
          ))}
        </Slider> */}
      </NavSlider>
    </Wrapper>
  )
}
  
ImageTextBannerSlider.propTypes = {
  items: propTypes.array.isRequired,
  heading: propTypes.string,
}

ImageTextBannerSlider.defaultProps = {
  heading: null
}

  
export default withWidth(ImageTextBannerSlider)
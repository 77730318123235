import React, {useRef, useState, useEffect} from 'react';
import dynamic from 'next/dynamic'
import propTypes from 'prop-types';
import {ReactSVG} from 'react-svg';
import styled from 'styled-components';

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import {Icon} from '../../ui';
import chevronLeft from '../../../assets/icons/chevron-left.svg';
import chevronRight from '../../../assets/icons/chevron-right.svg';

const ButtonGroupContainter = styled.div`
  text-align: center;

`

const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
  const { carouselState: { currentSlide } } = rest;
  return (
    <ButtonGroupContainter className={'carousel-arrow-group'}>
      <Icon grey {...rest} className={'carousel-arrow carousel-prev'} onClick={() => goToSlide(rest.carouselState.currentSlide - 1)}>
        <ReactSVG src={chevronLeft} />
      </Icon>
      <Icon grey {...rest} className={'carousel-arrow carousel-next'} onClick={() => goToSlide(rest.carouselState.currentSlide + 1)}>
        <ReactSVG src={chevronRight} />
      </Icon>
    </ButtonGroupContainter>
  );
};

const SsrCarousel = ({
  settings,
  children,
  showButtonGroup,
  forwardedRef,
}) => {
  const responsive = {
    all: {
      // the naming can be any, depends on you.
      breakpoint: {
        max: 4000, // theme.breakpoints.width('xl'),
        min: 0
      },
      items: 1
    }
  };
  var carouselSettings = {
    showDots: false,
    arrows: false,
    responsive,
    infinite: true,
    transitionDuration: 500,
    slidesToSlide: 1,
    renderButtonGroupOutside: true,
    customButtonGroup: showButtonGroup ? <ButtonGroup /> : null,
    ...settings,
    ssr: true, // enforce
  };
  const backupRef = useRef(null);

  const ref = forwardedRef ? forwardedRef : backupRef;

  return (
    <Carousel
      {...carouselSettings}
      ref={ref}
    >
      {children}
    </Carousel>
  )
}
  
SsrCarousel.propTypes = {
  settings: propTypes.object,
  forwardedRef: propTypes.object,
  arrowProps: propTypes.object,
  showButtonGroup: propTypes.bool,
}
  
SsrCarousel.defaultProps = {
  settings: {},
  forwardedRef: null,
  arrowProps: {
    grey: true
  },
  showButtonGroup: true,
}
  
export default SsrCarousel
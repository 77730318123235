const refIds = {
  "albertpark.jayco.com.au": "testRefId", // vi testing
  "jaycohobart.com.au" : "5a2bce4",
  "bayswaterjayco.com.au": "5a3d0d9",
  "jaycomildura.com.au": "58d64f1",
  "jaycoballarat.com.au": "659c665",
  "jaycogippsland.com.au": "6004df6",
  "pagebrosrv.com.au": "600f389",
  "jaycoadelaide.com.au": "66006f2",
  "mtgambier.jayco.com.au": "6603e23",
  "jaycodarwin.com.au": "6607554",
  "caravanland.com.au": "660ac85",
  "jaycoaw.com.au": "660e3b6",
  "jaycodubbo.com.au": "6611ae7",
  "jayconewcastle.com.au": "6615218",
  "jayconowra.com.au": "6618949",
  "jaycoorange.com.au": "661c07a",
  "jaycosydney.com.au": "661f7ab",
  "watsonsjayco.com.au": "662d46f",
  "jaycocanberra.com.au": "6630ba0",
  "jaycocairns.com.au": "66342d1",
  "jaycotownsville.com.au": "6637a02",
  "jaycomackay.com.au": "663b133",
  "jaycobundaberg.com.au": "663e864"
  // "jaycoadelaide.com.au": "308fdd4",
  // "mtgambier.jayco.com.au": "2fea4a4",
  // "kingcaravans.jayco.com.au": "30103bf",
  // "caravanland.com.au": "2fcb3eb",
  // "jaycodarwin.com.au": "307b2ae",
  // "jaycocairns.com.au": "3024ee5",
  // "jaycotownsville.com.au": "2ff1306",
  // "jaycobundaberg.com.au": "3028616",
  // "jaycoaw.com.au": "3017221",
  // "jayconewcastle.com.au": "30476cf",
  // "jayconowra.com.au": "3093505",
  // "jaycoorange.com.au": "2ffb899",
  // "jaycosydney.com.au": "2ff4a37",
  // "watsonsjayco.com.au": "2ffefca",
  // "mildura.jayco.com.au": "398ca7f",
  // "jaycoballarat.com.au": "31cd370",
  // "jaycogippsland.com.au": "30a11c9",
  // "pagebrosrv.com.au": "31d0aa1",
  // "horsham.jayco.com.au": "2fdc7e0",
  // "jaycocanberra.com.au": "391e45f"
}

export default refIds;
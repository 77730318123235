import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {ButtonBase} from '@material-ui/core';
import {ReactSVG} from 'react-svg';

import {Icon} from '../../ui';
import {theme} from '../../../themes/default-theme';
import Text from '../../typography';
import RespImg from '../../ui/RespImg';
import enlargeIcon from '../../../assets/icons/enlarge.svg';

const Wrapper = styled.div`
  overflow: hidden;
  background: white;
  border-top-right-radius: 30px;

  ${theme.breakpoints.up('md')} {
    border-top-right-radius: 15px;
  }

  ${theme.breakpoints.up('lg')} {
    border-top-right-radius: 30px;
  }
  
`

const ImgContainer = styled(ButtonBase)`
  overflow: hidden;
  position: relative;
  display: block;
  color: ${theme.palette.white};

  ${props => props.disableRipple && `
    cursor: auto;
  `} 

  ${theme.breakpoints.up('md')} {
    border-top-right-radius: 15px;
  }

  ${theme.breakpoints.up('lg')} {
    border-top-right-radius: 30px;
  }

  > div {
    transition: all 200ms ease-in-out;
    color: white;
  }

  ${props => props.disableRipple ? '' :
    `@media (hover: hover) {
      &:hover {
        > div {
          transform: scale(1.15, 1.15);
        }
      }
    }`
  }
`

const TextContainer = styled.div`
  padding: 20px 28px 16px;
  background: ${theme.palette.white};
  min-height: 84px;
  ${theme.breakpoints.down('sm')} {
    ${props => props.mobile === 0 && `
        display: none;
    `}
    padding: 20px 8px 16px;
   }
`

const IconWrapper = styled.span`
  position: absolute;
  right: 0;
  bottom: 0;
`

const Description = styled.div`
  h6 {
    margin-bottom: 0;
  }
`

const GalleryThumbnail = ({
  image,
  title,
  description,
  onClick,
  decor
}) => {
  return (
    <Wrapper>
  	  <ImgContainer disableRipple={decor} component="div" onClick={onClick ? onClick : () => {}}>
        <div>
          {image &&
          <RespImg 
            {...image}
            // lazyLoad={false} // affects masonry
          />
          }
        </div>
        {!decor &&
          <IconWrapper>
            <Icon gallery skyBlue>
              <ReactSVG src={enlargeIcon} />
            </Icon>
          </IconWrapper>
        }
      </ImgContainer>
      <TextContainer mobile={decor ? 1 : 0}>
        {decor ?
          <Description>
            {title && <Text variant="subtitle1" colour="vividBlue">{title}</Text>}
            {description && <Text variant="body1" colour="charcoal">{description}</Text> }
          </Description>
          : title ? <Text variant="body1">{title}</Text> : null
        }
      </TextContainer>
    </Wrapper>
  )
}

GalleryThumbnail.propTypes = {
  image: propTypes.object.isRequired,
  title: propTypes.string,
  description: propTypes.string,
  onClick: propTypes.func,
  decor: propTypes.bool
}

GalleryThumbnail.defaultProps = {
  title: null,
  description: null,
  decor: false,
  onClick: null
}

export default GalleryThumbnail
import React, {useEffect, useState} from 'react';
import propTypes from 'prop-types';
import Text from '../../typography'
import ReactHtmlParser from 'react-html-parser';
import {Grid, Box} from '@material-ui/core';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import refIds from '../../../util/refIds';

import Cta from '../../ui/Cta';

const CtaItem = styled.div`
  display: inline-block;
  ${props => props.align === 'left' ? `margin: 10px 10px 10px 0` : 'margin: 10px'};
`

const getScripts = (content) => {

  // var re = /<script\b[^>]*>([\s\S]*?)<\/script>/gm;
  var re = /<script.*?src="(.*?)"/gm;

  if (content) {
    var match;
    let scripts = []
    while (match = re.exec(content)) {
      // full match is in match[0], whereas captured groups are in ...[1], ...[2], etc.
      if (match && match[1]) {
        scripts.push(match[1]);
      }
    }
    return scripts
  }
}

const isMyScriptLoaded = (url) => {
  var scripts = document.getElementsByTagName('script');
  for (var i = 0; i < scripts.length; i++) {
    if (scripts[i].src === url) return true;
  }
  return false;
}

const BodyText = (props) => {

  const router = useRouter();
  const [refId, setRefId] = useState('');

  const {
    title,
    content,
    ctas,
    align,
    headerType,
    headerProps,
    onCtaClick,
    secondary,
    hTag
  } = props


  useEffect(() => {
    if (process.env.DEALER_SITE) {
      const ref = (window.staticly.dealerData.website && refIds[`${window.staticly.dealerData.website}`]) ? refIds[`${window.staticly.dealerData.website}`] : ''
      setRefId(ref);
    }
  }, [])

  // scripts get removed from react-dom so add them in manually
  useEffect(() => {
    const scripts = getScripts(content);
    console.log('bodyText scripts', scripts)
    scripts && scripts.forEach(item => {
      const onPage = isMyScriptLoaded(item);
      if (onPage) {
        // document.getElementById('https://synq-digital.leadshook.io/s/js_embed').remove()
        window.location.reload();
      } else {
        const script = document.createElement("script");
        script.src = item;
        script.id = item;
        script.async = true;
        document.body.appendChild(script);
      }
    })
  }, [content]);

  return (
    <Grid container className={'hasPadding'} spacing={0} direction={'row'} wrap="nowrap" justifyContent={'space-between'} align={align}>
      <Grid item xs={12}>
        <Box px={2}>
          {title && <Text variant={headerType} element={hTag ? hTag : headerType} size="large" colour="charcoal" uppercase={true} align={align} {...headerProps}>{title}</Text>}
          {content && <Text component={'div'} variant="body1" align={align}>{ReactHtmlParser(content)}</Text>}
          {ctas && ctas.map((item, index) => (
            <CtaItem key={index} align={align} onClick={() => onCtaClick(item)}>
              <Cta href={item.href.replace('[refId]', refId)} target={item.target} inline secondary={index % 2 === 0 ? false : secondary} bg={index % 2 === 0 ? item.colour : null} color={index % 2 !== 0 ? item.colour : null} outline={index % 2 !== 0 ? true : false}>{item.title}</Cta>
            </CtaItem>
          ))}
        </Box>
      </Grid>
    </Grid>
  )
}
  
BodyText.propTypes = {
  title: propTypes.string,
  content: propTypes.string,
  ctas: propTypes.array,
  align: propTypes.string,
  headerType: propTypes.string,
  headerProps: propTypes.object,
  onCtaClick: propTypes.func,
  secondary: propTypes.bool
}
  
BodyText.defaultProps = {
  title: null,
  content: null,
  ctas: [],
  align: "center",
  headerType: 'h2',
  headerProps: {},
  onCtaClick: () => {},
  secondary: false, // second cta to be light blue
}
  
export default BodyText
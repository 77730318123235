import React, {useState, useRef} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {Grid, Box as MuiBox} from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import {ReactSVG} from 'react-svg';
import withWidth, {isWidthUp} from '@material-ui/core/withWidth';
import Modal from '../../ui/Modal';

import {Cta, Icon, RespImg} from '../../ui';
import NextLink from '../../navigation/NextLink';
import {theme} from '../../../themes/default-theme';
import Text from '../../typography';
import YoutubeVideo from '../YoutubeVideo';
import LazyVideo from '../LazyVideo';
import chevronLeft from '../../../assets/icons/chevron-left.svg';
import chevronRight from '../../../assets/icons/chevron-right.svg';
import playIcon from '../../../assets/icons/play.svg';

const Arrows = styled.div`
  text-align: right;
  margin-top: 10px;
  padding-right: 16px;
  button {
    margin: 0 4.5px;
  }
`

const PlayBtn = styled.div`
  position: absolute;
  z-index: 3;
  left: 50%;
  margin: auto;
  text-align: center;
  transform: translate(-50%);
  top: 100px;

  ${theme.breakpoints.down("sm")} {
    top: 50%;
    transform: translate(-50%, -50%);
  }

  ${theme.breakpoints.up("lg")} {
    top: 50%;
    transform: translate(-50%, -50%);
  }
`

const PrevArrow = styled(props => {
  return (
    <div className={''}>
      <Icon grey {...props}>
        <ReactSVG src={chevronLeft} />
      </Icon>
    </div>
  )
})

const NextArrow = styled.div(props => {
  return (
    <Icon grey {...props}>
      <ReactSVG src={chevronRight} />
    </Icon>
  )}
)

const Container = styled.div`
  overflow: hidden;
  position: relative;

  ${theme.breakpoints.up("lg")} {
    min-height: 650px;
    max-width: ${theme.containers.full.width};
    margin: 0 auto;
  }
`

const BgAssetWrapper = styled.div`
  position: relative;
  overflow: hidden;
  border-top-${props => props.curve}-radius: 40px;

  ${theme.breakpoints.only("md")} {
    height: 340px;
    position: relative;
  }

  ${theme.breakpoints.up("md")} {
    picture,
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: auto;
      height: auto;
      max-height: none;
      max-width: none;
      min-height: 650px;
      min-width: 100%;
      transform: translate(-50%, -50%);
    }
  }


  ${theme.breakpoints.up("lg")} {

    border-top-${props => props.curve}-radius: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    height: auto;
    max-height: none;
    min-height: 100%;
    min-width: 100%;
    transform: translate(-50%, -50%);
  }
`

const VideoWrapper = styled.div`
  height: 0;
  padding-top: 46.25%;

  ${theme.breakpoints.up("md")} {
    height: auto;
    padding: 0;
  }

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    height: auto;
    max-height: none;
    max-width: none;
    min-width: 100%;
    min-height: 650px;
    transform: translate(-50%, -50%);
  
    ${theme.breakpoints.down("sm")} {
      width: 100%;
    }
  }
`

const Video = styled.video`
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  height: auto;
  max-height: none;
  max-width: none;
  min-width: 100%;
  min-height: 650px;
  transform: translate(-50%, -50%);

  ${theme.breakpoints.down("sm")} {
    width: 100%;
  }
`

const Wrapper = styled.div`

`

const Heading = styled(props => {
  return <Text {...props} />
})`
  margin-bottom: 10px;
  ${theme.breakpoints.up("md")} {
    text-align: center;
    margin-bottom: 50px;
  }
`

const Content = styled.div`
  position: relative;
  z-index: 1;

  ${theme.breakpoints.up("md")} {
    padding-left: 36px;
    padding-right: 36px;
    margin-top: -100px;

    &:before,
    &:after {
      display: table;
      content: ' ';
    }

    &:after {
      clear: both
    }
  }

  ${theme.breakpoints.up("lg")} {
    padding-left: 84px;
    padding-right: 84px;
    position: absolute;
    bottom: -1px;
    width: 100%;
    transform: none;
    margin-bottom: 0;
  }
`


const Box = styled.div`
  ${theme.breakpoints.up("md")} {

  }
`

const BoxContent = styled.div`
  padding 0 ${theme.containers.main.gutter};
  margin-top: 20px;

  ${theme.breakpoints.up("md")} {
    background: white;
    border-top-${props => props.curve}-radius: 58px;
    font-size: 16px;
    padding: 30px 30px 25px;
    margin-top: 0
  }

  ${theme.breakpoints.up("lg")} {
    padding: 60px 60px 20px;
  }

  a {
    text-decoration: none;
  }
  
`

const BoxContentCta = styled.div `
  display: block;
  margin-bottom: 10px;
`

const OverlayVideoContainer = styled.div`
  width: 100%;
  max-width: 1066px;
  margin: 0 auto;
`

const OverlayVideoWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(0,0,0,0.5);
`

const BoxContentText = styled.div`
  margin: 15px 0;
  
  ${theme.breakpoints.down("sm")} {
    font-size: 13px;
  }
`

const MultiContentBanner = (props) => {
  const {
    heading,
    bgImage,
    bgVideoDesktop,
    bgVideoMobile,
    alignContent,
    content,
    mobileArrows,
    width,
    onPrevClick,
    onNextClick,
    videoRef,
    video,
  } = props

  const [modal, openModal] = useState(false);
  const inlineVideoRef = useRef(null);
  const vidRef = videoRef ? videoRef : inlineVideoRef

  const handleModal = (e, val) => {

    openModal(val ? val : !modal);

    if (vidRef && vidRef.current) {
      if (!modal) {
        vidRef.current.pause();
      } else {
        vidRef.current.play();
      }
    }
  }

  const PlayButton = () => (
    <PlayBtn>
      <Icon onClick={handleModal} play large>
        <ReactSVG src={playIcon} />
      </Icon>
    </PlayBtn>
  );

  return (
    <Wrapper>

      {video &&
        <Modal
          isOpen={modal}
          onClose={(e) => handleModal(e, false)}
        >
          <OverlayVideoContainer>
            <YoutubeVideo
              url={video}
              autoPlay
            />
          </OverlayVideoContainer>
        </Modal>
      }
  
      {heading &&
        <Grid container spacing={0} direction={'row'} wrap="nowrap" justify={'space-between'} align={'flex-start'} >
          <Grid item xs={12}>
            <MuiBox px={2}>
              <Heading variant="h2" size="extraLarge" colour="charcoal" uppercase={true}>{heading}</Heading>
            </MuiBox >
          </Grid>
        </Grid>
      }
      <Container>
        {video && 
          isWidthUp('md', width) && <PlayButton />
        }
        <BgAssetWrapper curve={alignContent.toLowerCase()}>
          {bgImage && (!bgVideoDesktop || !bgVideoDesktop.src) && (!bgVideoMobile || !bgVideoMobile.src) &&
            <RespImg
              {...bgImage}
            />
          }
          {video && 
            !isWidthUp('md', width) && <PlayButton />
          }
          {(bgVideoDesktop && bgVideoDesktop.src) && (bgVideoDesktop && bgVideoMobile.src) &&
            <VideoWrapper>
              {isWidthUp('md') ? 
                // <Video muted autoPlay loop ref={vidRef} playsInline key={bgVideoDesktop.id || undefined}>
                //   <source src={bgVideoDesktop.src} type={bgVideoDesktop.mimeType} />
                // </Video>
                <LazyVideo
                              
                  attributes={{
                    muted:"true", 
                    autoPlay:"true", 
                    loop:"true", 
                    playsInline:"true"
                  }}
                  innerRef={vidRef} key={bgVideoDesktop.id || undefined}>
                    <source src={bgVideoDesktop.src} type={bgVideoDesktop.mimeType} />
                </LazyVideo>
               :
                <LazyVideo   
                     
                  attributes={{
                    muted:"true", 
                    autoPlay:"true", 
                    loop:"true", 
                    playsInline:"true"
                  }} 
                  innerRef={vidRef} key={bgVideoMobile.id || undefined}>
                    <source src={bgVideoMobile.src} type={bgVideoMobile.mimeType} />
                </LazyVideo>
                // <Video muted autoPlay loop ref={vidRef} playsInline key={bgVideoMobile.id || undefined}>
                //   <source src={bgVideoMobile.src} type={bgVideoMobile.mimeType} />
                // </Video>
               }
            </VideoWrapper>
          }
        </BgAssetWrapper>
        {content &&
          <Content>
            <Box>
              <Grid container justify={alignContent.toLowerCase() === 'left' ? 'space-between' : 'flex-end'} spacing={0} direction={'row'} wrap="nowrap" align={'flex-start'}>
                <Grid item xs={12} md={5}>
                  {(mobileArrows && !isWidthUp('md', width)) &&
                    <Arrows>
                      <Icon grey onClick={onPrevClick}>
                        <ReactSVG src={chevronLeft} />
                      </Icon>
                      <Icon grey onClick={onNextClick}>
                        <ReactSVG src={chevronRight} />
                      </Icon>
                    </Arrows>
                  }
                  {(content.title || content.text || content.ctas) &&
                    <BoxContent curve={alignContent.toLowerCase() === 'left' ? 'right' : 'left'}>
                      <Text variant="h6" colour="vividBlue" uppercase>{content.title}</Text>
                      {content.text && <BoxContentText><div>{ReactHtmlParser(content.text)}</div></BoxContentText> }
                      {content.ctas && content.ctas.map((cta, index) => (
                        <BoxContentCta key={cta.href || index}>
                          <NextLink href={cta.url}>
                            <a target={cta.target}>
                              <Cta small inline component="span">{cta.title}</Cta>
                            </a>
                          </NextLink>
                        </BoxContentCta>
                        ))
                      }
                    </BoxContent>
                  }
                </Grid>
              </Grid>
            </Box>
          </Content>
        }
      </Container>
    </Wrapper>
  )
}
  
MultiContentBanner.propTypes = {
  alignContent: propTypes.oneOf(['left', 'right']),
  heading: propTypes.string,
  bgImage: propTypes.object,
  bgVideo: propTypes.object,
  content: propTypes.shape({
    title: propTypes.string,
    text: propTypes.string,
    cta: propTypes.object
  }),
  mobileArrows: propTypes.bool,
  onPrevClick: propTypes.func,
  onNextClick: propTypes.func,
  videoRef: propTypes.object,
  video: propTypes.string
}
  
MultiContentBanner.defaultProps = {
  alignContent: 'left',
  bgImage: null,
  heading: null,
  mobileArrows: false,
  onPrevClick: () => {},
  onNextClick: () => {},
  bgVideo: {},
  videoRef: null,
  video: null
}
  
export default withWidth()(MultiContentBanner)
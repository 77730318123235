import React, {useRef} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {Grid, Box as MuiBox} from '@material-ui/core';

import {theme} from '../../../themes/default-theme';
import MultiContentBanner from '../MultiContentBanner';
import Carousel from '../Carousel';
import getOrCreateRef from '../../../util/getOrCreateRef';
import Text from '../../typography';

const Container = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  position: relative;

  .carousel-arrow-group {
    display: none;

    ${theme.breakpoints.up("md")} {
      display: block;
    }

    ${theme.breakpoints.up("lg")} {
      margin: 20px;
    }
  }

  .carousel-arrow {
    margin: 10px;
  }

`

const Heading = styled(props => {
  return <Text {...props} />
})`
  margin-bottom: 10px;
  ${theme.breakpoints.up("md")} {
    text-align: center;
    margin-bottom: 50px;
  }
`

let videoReferences = [];

const MultiContentBannerSlider = ({
  settings,
  items,
  heading,
  hTag
}) => {

  const sliderRef = useRef(null);

  const handlePrevClick = index => {
    const prevSlide = sliderRef.current.state.currentSlide - 1;
    sliderRef.current.goToSlide(prevSlide)
  }

  const handleNextClick = index => {
    const nextSlide = sliderRef.current.state.currentSlide + 1;
    sliderRef.current.goToSlide(nextSlide)
  }

  const carouselSettings = {
    // responsive: {
    //   {
    //     breakpoint: theme.breakpoints.width('md'),
    //     settings: {
    //       arrows: false,
    //     }
    //   }
    // },
    speed: 500,
    beforeChange: (oldIndex, newIndex) => resetVid(oldIndex, newIndex),
    infinite: items.length > 1 ? true : false,
    arrows: false, // items.length > 1 ? true : false,
    ...settings,
  }

  const resetVid = (oldIndex, newIndex) => {

    const oldRef = videoReferences[oldIndex];
    const newRef = videoReferences[newIndex];

    if (oldRef && oldRef.current) {
      // Wait until transition complete
      setTimeout(() => { 
        oldRef.current.currentTime = 0;
        oldRef.current.pause();
      }, carouselSettings.speed || 500);
    }

    if (newRef && newRef.current) {
      newRef.current.play();
    }
  }

  return (
    <Container>
      {heading &&
        <Grid container spacing={0} direction={'row'} wrap="nowrap" justify={'space-between'} align={'flex-start'} >
          <Grid item xs={12}>
            <MuiBox px={2}>
              <Heading variant="h2" element={hTag ? hTag : 'h2'} size="extraLarge" colour="charcoal" uppercase={true}>{heading}</Heading>
            </MuiBox >
          </Grid>
        </Grid>
      }
      {/* {process.env.DEALER_SITE ?
        <>
          <Carousel
            forwardedRef={sliderRef}
            showButtonGroup={false}
            settings={carouselSettings}
          >
            <MultiContentBanner
              mobileArrows={false}
              onPrevClick={() => {handlePrevClick('{{@index}}')}}
              onNextClick={() => {handleNextClick('{{@index}}')}}
              videoRef={getOrCreateRef('{{@index}}', videoReferences)}
              slider={true}
              heading={'{{ibcTitle}}'}
            />
          </Carousel>
        </>
      : */}
        { items && typeof(items) === 'object' &&
          <Carousel
            forwardedRef={sliderRef}
            showButtonGroup={items.length > 1 ? true : false}
            settings={carouselSettings}
          >
          
            {items.map((item, index) =>  (
              <MultiContentBanner
                key={index}
                mobileArrows={items.length > 1 ? true : false}
                onPrevClick={() => {handlePrevClick(index)}}
                onNextClick={() => {handleNextClick(index)}}
                videoRef={getOrCreateRef(index, videoReferences)}
                slider={true}
                {...item}
              />
            ))}
          </Carousel>
        }

    </Container>
  )
}

MultiContentBannerSlider.propTypes = {
  settings: propTypes.object,
  items: propTypes.array.isRequired,
  heading: propTypes.string
}
  
MultiContentBannerSlider.defaultProps = {
  settings: {},
  items: [],
  heading: null
}
  
export default MultiContentBannerSlider
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme } from '../../../themes/default-theme';
import Typography from '@material-ui/core/Typography';
import IconLink from '../../ui/IconLink';
import NextLink from '../NextLink';
import RespImg from '../../ui/RespImg';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import ReactHtmlParser from 'react-html-parser';

const Quicklink = styled.div`
  width: 100%;
  position:relative;
  display: flex;
  flex-direction: column;

  ${theme.breakpoints.down("sm")} {
    margin-top:30px;
    &:first-child {
      margin-top:0;
    }
  }
  ${theme.breakpoints.only("md")} {
    margin-top:1px;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:flex-start;
    width:100%;
    > a {
      width:50%;
    }
  }
  ${theme.breakpoints.up("lg")} {
    padding-bottom:0px;
    justify-content:space-between;
  }
`
const Image = styled.div`
  width: 100%;
  vertical-align:middle;
  height: 0;
  padding-top: 67%;
  overflow: hidden;
  position: relative;
  ${theme.breakpoints.up("sm")} {
    padding-top: 58%;
  }
  ${theme.breakpoints.up("md")} {
    padding-top: 67%;
  }
  picture,
  img {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
  }
`

const QuicklinkTextContainer = styled.div`
  padding: 24px 24px 0;
  ${theme.breakpoints.only("md")} {
    padding: 0 0 0 30px;
    width: 100%;
  }
  ${theme.breakpoints.down("sm")} {
    padding:20px 0 0 0;
  }
`

const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${theme.palette.charcoal};
  margin-bottom: 10px;
  font-weight:800;
  color:${theme.palette.vividBlue};
  ${theme.breakpoints.only("md")} {
    font-size: 14px;
    line-height:20px;
    margin-bottom: 10px;
  }
  ${theme.breakpoints.down("sm")} {
    font-size: 16px;
    margin-bottom: 10px;
  }
`

const Caption = styled.div`
  font-size: 16px;
  color: ${theme.palette.gunmentalGrey};
  margin-bottom: 20px;
  ${theme.breakpoints.only("md")} {
    font-size: 12px;
    margin-bottom: 10px;
    letter-spacing: .2px;
  }
  ${theme.breakpoints.down("sm")} {
     margin-bottom: 10px;
  }
`

const Link = styled(IconLink)`

`

const Top = styled.div`
  width: 100%;
  ${theme.breakpoints.only("md")} {
    width: 100%;
    display: flex;
    align-items: center;

    a, > span {
      width: 100%;
      display: block;
    }
  }
`

const Bottom = styled.div`
  padding: 0 24px 0;
  ${theme.breakpoints.only("md")} {
    padding: 0;
    width:50%;
  }
  ${theme.breakpoints.down("sm")} {
    padding:0 0 20px 0;
  }
`

export const QuicklinkStyled = props => {
  const {
    image,
    title,
    caption,
    href,
    hrefTitle
  } = props;

  return (
    <Quicklink>
      <Top>
        <NextLink href={href || '#'} >
          <a aria-label={title}>
            {image &&
              <Image className="quicklinks-image">
                <RespImg
                  {...image}
                  blur={true}
                />
              </Image>
            }
          </a>
        </NextLink>

        <QuicklinkTextContainer>
          {title &&
            <Title>{title}</Title>
          }
          {caption &&
            <Caption>{ReactHtmlParser(caption)}</Caption>
          }
          {(!isWidthUp('lg', props.width) && href && hrefTitle) &&
            <Typography>
              <Link colour="vividBlue" href={href || '#'} link={{ href, title: hrefTitle }} />
            </Typography>
          }
        </QuicklinkTextContainer>

      </Top>
      {isWidthUp('lg', props.width) &&
        <Bottom>
          {(href && hrefTitle) &&
            <Typography>
              <Link colour="vividBlue" href={href || '#'} link={{ href, title: hrefTitle }} />
            </Typography>
          }
        </Bottom>
      }
    </Quicklink>
  )
}

QuicklinkStyled.propTypes = {
  image: PropTypes.object,
  title: PropTypes.string,
  caption: PropTypes.string,
  href: PropTypes.string,
  hrefTitle: PropTypes.string
}
QuicklinkStyled.defaultProps = {
  image: null,
  title: null,
  text: null,
  href: null,
  hrefTitle: null
}

export default withWidth()(QuicklinkStyled);